import React from 'react';

import {DefaultLayout} from '../components/layout';
import {QueryData} from '../common/types/query-data';

const Gallery = (props: {data: QueryData}) => {

  return (
    <div>
      <DefaultLayout>
        gallery
      </DefaultLayout>
    </div>
  );
};

export default Gallery;
